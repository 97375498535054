import React, { FunctionComponent } from "react";

export interface TitleProps {
  small?: boolean;
  large?: boolean;
  xlarge?: boolean;
  className?: string;
  id?: string;
  dark?: boolean;
  type?: "h1" | "h2" | "h3" | "h4" | "p";
}

const Title: FunctionComponent<TitleProps> = ({
  children,
  small,
  large,
  xlarge,
  className,
  id,
  dark = false,
  type = "h2",
}) => {
  return React.createElement(type, {
    id,
    children,
    className: `${
      dark ? "text-white" : "text-gray-900"
    } font-extrabold tracking-wide ${
      small
        ? `text-xl sm:text-xl mb-0`
        : large
        ? "text-4xl sm:text-4xl mb-4"
        : xlarge
        ? "text-5xl sm:text-5xl mb-4"
        : "text-3xl sm:text-3xl mb-2"
    } ${className}`,
  });
};

export default Title;
